import React, {useState} from 'react'
import './style.css'
import "./cardCarouselStyle.css"
import "./sectionStyle.css"
import "./showcaseStyle.css"
import ImageGallery from 'react-image-gallery';

import swimmingPool from '../../assets/images/apartments/swimming_pool1.JPG';
import apartment from '../../assets/images/apartments/single_apartment_out_door_view.JPG';
import contactBackground from '../../assets/images/apartments/apartments_out_door_super1.JPG';
import apartments2 from '../../assets/images/apartments/apartments_out_door_super.JPG';

import OwlCarousel from "react-owl-carousel";
import {useForm} from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import ReactLoading from "react-loading";


const style = {
    root: {},
    textSectionHeading: {
        margin: "0px",
        fontWeight: 600,
        fontSize: "50px",
        textAlign: "center",
        lineHeight: 1,
        marginBottom: "0.1rem",
        color: "rgb(222, 5, 12)"
    },
    textSectionContainer: {
        display: 'flex',
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        maxWidth: '80%',
        height:'2rem',
        paddingTop: "5rem",
        marginBottom: "-0.5rem",
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",

    },
    showcase:{
      height:"5rem"
    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
        padding:0,
        marginBottom:-10
    }
}

const styles = {
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
    }
}

export default () => {
    const {register, handleSubmit, watch, formState: {errors},reset} = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)


    const onSubmit = data => {
        setLoading(true);
        MySwal.fire({
            title: 'Sending email',
            color:'rgb(254, 45, 45)',
            allowOutsideClick: false,
            html: (
                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection:"column"
                }}>
                    <h4>Please wait..</h4>
                    <ReactLoading type={'bubbles'} color='rgb(254, 45, 45)' height={'20%'} width={'20%'} />
                </div>
            ),
            showConfirmButton: false,
        });
        axios.post('https://data.mongodb-api.com/app/application-0-mbwce/endpoint/email/apartments', data)
            .then(response => {
                MySwal.fire({
                    icon: 'success',
                    title: 'Email sent successfully!',
                    text: 'Thank you for your message.',
                });
                reset()
                setLoading(false);
            })
            .catch(error => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong. Please try again later.',
                });
                setLoading(false);
            });
        setFormSubmitted(true);
    };

    function importAll(r) {
        let images = [];
        r.keys().map((item, index) => {
            images.push( {
                original: r(item),
                thumbnail: r(item),
                originalHeight: '10px',
            })
            ; }
        );
        return images;
    }

    const images = importAll(require.context('../../assets/images/apartments', false, /\.(JPG)$/));

    const bedrooms = images.filter(imageObject => imageObject.original.includes("bedroom"));
    const sittingrooms = images.filter(imageObject => imageObject.original.includes("sittingroom"));
    const swimmingPools = images.filter(imageObject => imageObject.original.includes("swimming"));
    const bathrooms = images.filter(imageObject => imageObject.original.includes("bathroom"));

    console.log("bedrooms",bedrooms)
    console.log("sittingrooms",sittingrooms)
    console.log("swimmingPool",swimmingPool)



    return (
        <div style={style.root}>

            <header>
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    nav
                    dots={false}
                    mouseDrag={false}
                    autoplay={true}
                    autoplayTimeout={10000}
                    responsive={
                        {
                            0: {
                                items: 1
                            },
                            600: {
                                items: 1
                            },
                            1000: {
                                items: 1
                            }
                        }
                    }
                >

                    <div className="item">
                        <img src={apartments2}
                             alt="images not found"/>
                        <div className="cover">
                            <div className="container">
                                <div className="header-content">
                                    <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                    <h1>Our Apartments</h1>
                                    <h4 style={{textAlign:"left",marginLeft:"auto",marginRight:"auto", alignSelf:"center"}}>
                                        - 3 bedrooms (self contained master bedrooms)<br/>
                                        - Air conditioned master bedrooms <br/>
                                        - Air conditioned living rooms<br/>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={swimmingPool}
                             alt="images not found"/>
                        <div className="cover">
                            <div className="container">
                                <div className="header-content">
                                    <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                    <h1> Swimming Pool</h1>
                                    <h4 style={{textAlign:"left"}}>
                                        - Communal pool<br/>
                                        - Shower<br/>
                                        - Braai area <br/>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={apartment}
                             alt="images not found"/>
                        <div className="cover">
                            <div className="container">
                                <div className="header-content">
                                    <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                    <h1>Our Offering</h1>
                                    <h4 style={{textAlign:"left",marginLeft:"auto",marginRight:"auto", alignSelf:"center"}}>
                                        - CCTV<br/>
                                        - Smart TVs <br/>
                                        - Fully Equipped Kitchens<br/>
                                        - Ample Parking Space<br/>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </header>

            <div className="media-block" id="c-258504">
                <div className="media-block__desc" style={style.textSectionContainer}>
                    <h3 style={style.textSectionHeading}>Bedrooms</h3>
                    <p style={style.subText}>
                        Browse through our gallery of state of the art apartments
                    </p>
                </div>
            </div>

            <ImageGallery showFullscreenButton={false}   style={style.showcase} items={bedrooms} />

            <div className="media-block" id="c-258504">
                <div className="media-block__desc" style={style.textSectionContainer}>
                    <h3 style={style.textSectionHeading}>Living Rooms</h3>
                    <p style={style.subText}>
                       Browse through our gallery of state of the art apartments
                    </p>
                </div>
            </div>

            <ImageGallery style={style.showcase} showFullscreenButton={false} items={sittingrooms} />

            <div className="media-block" id="c-258504">
                <div className="media-block__desc" style={style.textSectionContainer}>
                    <h3 style={style.textSectionHeading}>Bathrooms</h3>
                    <p style={style.subText}>
                       state of the art luxurious and comfortable bathroom space that offers a range of modern amenities and premium finishes.
                    </p>
                </div>
            </div>

            <ImageGallery items={bathrooms} showFullscreenButton={false} />

            <main className="row section--navy section--dark" id="row-1" id="main" role="main"
                  style={{
                      backgroundColor: 'rgb(222, 5, 12)',
                      backgroundRepeat: 'no-repeat',
                      backgroundBlendMode: 'hue',
                      marginBottom: 10,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      alignContent:"center"}}>

                            <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                 style={{
                                     backgroundPosition: 'left top',
                                     backgroundBlendMode: 'overlay',
                                     backgroundRepeat: 'no-repeat',
                                     backgroundSize: 'cover',
                                     minHeight: '0px',
                                     display: "flex",
                                     flexDirection: "column",
                                     alignItems: "center",
                                     textAlign: "center",
                                     padding: 30,
                                     alignContent:"center",
                                     marginLeft:"auto",
                                     marginRight:"auto",
                                     maxWidth:"40rem"

                                 }}
                            >
                                <h1 className="h0 hero__heading">
                                    <span className="u-nowrap-sm">Contact Us</span>
                                </h1>
                                <p style={{...styles.subText,color:"white", width:"900px",textAlign:"justify"}}>
                                    Longhorn Associates has a team of qualified and experienced professionals from diverse
                                    fields serving on its Board and providing governance oversight of the institution.<br/>
                                    <br/>

                                </p>
                                <p style={{...styles.subText,color:"white",fontWeight:"800",width:"900px",textAlign:"justify"}}>
                                    Call: +260 750191241 <br/>
                                    Email: apartments@longhorn-associates.com <br/>
                                </p>
                            </div>


                <div id="contact" className="contact" style={{marginTop: -10}}>
                    <form onSubmit={handleSubmit(onSubmit)} className="main_form"
                          style={{backgroundColor: "#fff", width: "65%", marginLeft: "auto", marginRight: "auto"}}>

                        <div className="row">

                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="Name"
                                    placeholder={`${errors.Name ? "This name is required" : "name"}`}
                                    className={`${errors.Name ? "invalid_input" : "form_contril"}`}
                                    {...register("Name", {required: true})}
                                />
                            </div>

                            <div className="col-md-12">
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    placeholder={`${errors.phoneNumber ? "This phone number is required" : "phoneNumber"}`}
                                    className={`${errors.phoneNumber ? "invalid_input" : "form_contril"}`}
                                    {...register("phoneNumber", {required: true})}
                                />
                            </div>

                            <div className="col-md-12">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={`${errors.email ? "This email is required" : "email"}`}
                                    className={`${errors.email ? "invalid_input" : "form_contril"}`}
                                    {...register("email", {required: true,pattern: /^\S+@\S+$/i})}
                                />
                            </div>

                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder={`${errors.subject ? "This subject is required" : "subject"}`}
                                    className={`${errors.subject ? "invalid_input" : "form_contril"}`}
                                    {...register("subject", {required: true})}
                                />

                            </div>

                            <div className="col-md-12">
                                    <textarea
                                        name="message"
                                        defaultValue={""}
                                        placeholder={`${errors.message ? "This message is required" : "message"}`}
                                        className={`${errors.message ? "invalid_input" : "form_contril"}`}
                                        {...register("message", {required: true})}
                                    />
                            </div>

                            <div className="col-sm-12">
                                {loading ? (
                                    <button disabled={true} className="send_btn" >Sending</button>
                                ) : (
                                    <button className="send_btn" type="submit">Send</button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>

                <div style={{
                    marginTop:40,
                    height: 3,
                    width: "50%",
                    backgroundColor: 'white',
                    marginRight: "auto",
                    marginLeft: "auto"
                }}/>

                <h1 style={{marginTop: 20, color: "white", marginLeft: 'auto', marginRight: "auto"}}>Pay us a visit</h1>
                <div style={{
                    height: 3,
                    width: "50%",
                    backgroundColor: 'white',
                    marginRight: "auto",
                    marginLeft: "auto"
                }}/>
                <div style={{width:"100%",height:800,margin:30,marginTop:50}}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.375874841212!2d28.301944014850065!3d-15.4210825,28.3413301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408b71d3dbb959%3A0xd648e3f01a839f7d!2sLonghorn+Associates+Limited!5e0!3m2!1sen!2ske!4v1536235728739"
                        width={1100}
                        height={500}
                        frameBorder={0}
                        style={{border: 0}}
                        allowFullScreen/>

                    <h3 style={{marginTop: 60, color: "white", marginLeft: 'auto', marginRight: "auto"}}> We are located at</h3>

                    <div style={{
                        marginTop:40,
                        marginBottom:20,
                        height: 3,
                        width: "50%",
                        backgroundColor: 'white',
                        marginRight: "auto",
                        marginLeft: "auto"
                    }}/>
                    <h4 style={{
                        textAlign:"left",
                        marginRight: "auto",
                        marginLeft: "auto",
                        width: "38%",

                    }}>
                        Zambia Lusaka<br/>
                        10565 Kabulonga Road<br/>
                        Walkable distance to Central Mall (70 Meters).

                    </h4>
                    <div style={{
                        marginTop:20,
                        marginBottom:40,
                        height: 3,
                        width: "50%",
                        backgroundColor: 'white',
                        marginRight: "auto",
                        marginLeft: "auto"
                    }}/>
                </div>
            </main>
        </div>
    );
}

