import React from 'react'
import {Link, useLocation} from "react-router-dom";
import serviceDetails from '../serviceDetailsData'
import "./style.css"


const styles = {
    main: {
        padding: 0,
        margin: 0
    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
        textAlign: "justify"
    }
}
const style = {
    main: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: 1,
    },
    image: {
        objectFit: "cover",
    },
    imageArea: {
        paddingTop: 50,
        display: "flex",
        width: "100%",
        height: "100%",
        flexGrow: 1,
        backgroundColor: "rgb(254, 45, 45)",
        justifyContent: "center",
        alignContent: "center",
        paddingBottom: 50,
        borderBottomRightRadius: 100,

    },
    textArea: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
    },
    textContainer: {
        width: "100",
        paddingLeft: "auto",
        paddingRight: "auto",
        margin: 50,
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
    }
}


export default () => {
    const location = useLocation()
    const id = new URLSearchParams(location.search).get('id')
    const service = serviceDetails.find(service => service.id == id)

    const showUnitTrust = () => {
        if (service.unitTrust != null)
            return service.unitTrust.map(unit =>
                <div style={{ textAlign: "justify", marginTop: 10}}>
                    <h4 style={{ color: "#000080",fontSize:"16px"}} >{unit.title}</h4>
                    <p style={{ color: "black"}}>{unit.description}</p>
                </div>
            )
    }

    const showServicePoints = () => {
        if (service.points != null)
            return <div style={{textAlign: "left",marginLeft:15}}>
                <ul>
                    {service.points.map(point =>
                        <li style={{listStyleType: "circle",
                            listStylePosition:"outside",
                            alignText: "left"}}>
                            <p  style={{...styles.subText,fontSize:17,  fontWeight: 510,}}>{point}</p>
                        </li>)
                    }
                </ul>
            </div>
    }

    return (
        <section style={style.main}>
            <div className="row section--navy section--dark" id="row-1"
                 style={{
                     width: "100%",
                     height: 200,
                     justifyContent: "center",
                     alignContent: "center",
                     display: "flex",
                     alignText: "center"
                 }}
            >
                <section
                    id="c-258500"
                    role="banner"
                    className="hero  hero--dark hero--with-image"
                >
                    <h1 className="h0 hero__heading">
                        <span className="u-nowrap-sm"> {service.title} </span>
                    </h1>
                </section>
            </div>

            <section className="lesun-single-service-area section_100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="service-details-left">
                                <div className="single-service-widget">
                                    <ul>

                                        <li>
                                            <Link to={"/services"}>
                                                All Products &amp; Services
                                            </Link>
                                        </li>
                                        <li className={id == 2 ? "active" : ""}>
                                            <Link to={"/service?id=2"}>
                                                Pension Fund Management
                                            </Link>
                                        </li>
                                        <li className={id == 3 ? "active" : ""}>
                                            <Link to={"/service?id=3"}>
                                                Longhorn Unit Trust
                                            </Link>
                                        </li>
                                        <li className={id == 1 ? "active" : ""}>
                                            <Link to={"/service?id=1"}>
                                                Securities Trading &amp; Brokerage services
                                            </Link>
                                        </li>
                                        <li className={id == 5 ? "active" : ""}>
                                            <Link to={"/service?id=5"}>
                                                Risk Management
                                            </Link>
                                        </li>

                                        <li className={id == 4 ? "active" : ""}>
                                            <Link to={"/service?id=4"}>
                                                Advisory &amp; Support Services
                                            </Link>
                                        </li>




                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9" style={{backgroundColor: "rgb(249, 249, 249)"}}>
                            <div className="single-service-right margin-top">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    height: 150,
                                    width: 150,
                                    backgroundColor: "red",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    borderRadius: 100
                                }}
                                >
                                    <img style={{height: 80, width: 80}} src={service.icon}/>
                                </div>
                                <h1 style={{color: "#de050c", fontSize: 35}}>{service.title}</h1>
                                <div style={{backgroundColor: "red", width: "100%", height: 2}}/>

                                <p style={styles.subText} dangerouslySetInnerHTML={{__html: service.description}}/>
                                {showServicePoints()}

                                <div style={{backgroundColor: "red", width: "100%", height: 2, marginBottom: 60}}/>
                                {
                                    showUnitTrust()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}
