import React from 'react'
import "./cardStyle.css"
import {Link} from "react-router-dom";
import operator from "../../assets/icons/operator.svg";
import brokerage from "../../assets/icons/brokerage.svg";
import risk from "../../assets/icons/risk.svg";
import pension from "../../assets/icons/pension.svg";
import investors from "../../assets/icons/investors.svg";

const style = {
    root: {},
    textSectionHeading: {
        margin: "0px",
        fontWeight: 600,
        fontSize: "50px",
        textAlign: "center",
        lineHeight: 1,
        marginBottom: "2rem",
        color: "rgb(222, 5, 12)"
    },
    textSectionContainer: {
        display: 'flex',
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        maxWidth: '80%',
        paddingTop: "5rem",
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",

    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
    }
}

export default () => {
    return (
            <div
                className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                style={{
                    backgroundColor: 'rgba(255,255,255,0)',
                    backgroundPosition: 'left top',
                    backgroundRepeat: 'no-repeat',
                    paddingBottom: '70px',
                    marginBottom: '0px',
                    marginTop: '0px',
                    borderWidth: '0px 0px 0px 0px',
                    borderColor: '#eae9e9',
                    borderStyle: 'solid'
                }}>

                <div style={{
                    backgroundColor: "rgb(249, 249, 249)",
                    width: "100%"
                }}>
                    <div className="card-container" style={{ display:"flex",justifyContent:"space-between"}}>


                        <div className="card" style={{maxWidth:260}}>
                            <div className="img-container">
                                <img src={pension}/>
                            </div>
                            <h3 className="card-title">Pension Fund Management</h3>
                            <p className="card-description">As a Fund Manager, Longhorn Associates manages pension funds and
                                invests in a diverse...
                            </p>
                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/service?id=2"} className="read_more" style={{borderColor:"#fff",color:"#fff"}}>Read More </Link>
                                </div>
                            </Link>
                        </div>
                        <div className="card" style={{maxWidth:260}}>
                            <div className="img-container">
                                <img src={investors}/>
                            </div>
                            <h3 className="card-title">Longhorn Unit Trust</h3>
                            <p className="card-description">This is a Collective Investment Scheme under which various funds ...
                            </p>

                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/service?id=3"} className="read_more" style={{borderColor:"#fff",color:"#fff"}}>Read More </Link>
                                </div>
                            </Link>
                        </div>
                        <div className="card" style={{maxWidth:260}}>
                            <div className="img-container" >
                                <img src={brokerage}/>
                            </div>
                            <h3 className="card-title" style={{fontSize:20}}>
                                Securities Trading &amp; Brokerage services
                            </h3>
                            <p className="card-description">
                                Longhorn is licensed by the Lusaka Securities Exchange to provide ...
                            </p>
                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    height: 40,
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/service?id=1"} className="read_more" style={{borderColor:"#fff",color:"#fff"}}>Read More </Link>
                                </div>
                            </Link>
                        </div>




                    </div>

                    <div className="card-container" style={{marginTop:50, display:"flex",justifyContent:"space-around"}}>
                        <div className="card" style={{maxWidth:260}}>
                            <div className="img-container">
                                <img src={risk}/>
                            </div>
                            <h3 className="card-title">
                                Risk Management
                            </h3>
                            <p className="card-description">
                                Through its focused research into the global economy and markets...
                            </p>

                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    height: 40,
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/service?id=5"} className="read_more" style={{borderColor:"#fff",color:"#fff"}}>Read More </Link>
                                </div>
                            </Link>

                        </div>
                        <div className="card" style={{maxWidth:260}}>
                            <div className="img-container">
                                <img src={operator}/>
                            </div>
                            <h3 className="card-title" >Advisory &amp; Support Services</h3>
                            <p className="card-description">Longhorn Associates has forged partnerships with international
                                institutions for...</p>
                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/service?id=4"}className="read_more" style={{borderColor:"#fff",color:"#fff"}}>Read More </Link>
                                </div>
                            </Link>
                        </div>


                    </div>
                </div>
            </div>
    );


}
