import React from 'react'
// import "./cardStyle.css"
import {Link} from "react-router-dom";
import teamDetails from './memberDetails'


const style = {
    separator: {
        height: 3,
        width: "50%",
        backgroundColor: 'red',
        marginRight: "auto",
        marginLeft: "auto"
    },
    memberPosition: {
        fontSize:20,
        color: "grey",
        marginTop: 20,
        marginBottom: 0
    },
    memberImage:{
        borderRadius: 30,
    },
    imageContainer:{
        height:260,
        overflow:"hidden"
    }
}

export default () => {
    return (
        <section className="bg-contrast">
            <div style={{marginRight: 'auto', marginLeft: "auto", marginBottom: 100}}>
                <h3 style={{marginTop:-100}} >Board of Directors</ h3>
                <div style={style.separator}/>
            </div>
            <div className=" service-grid">
                {teamDetails.map(member  => {
                    if(member.type === "BoardMember") return(
                    <div className="card" id={member.id}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: 'center',
                            alignItems: 'center'
                        }}>
                            <div style={style.imageContainer}>
                                <img style={style.memberImage} src={member.image} alt={"profile"}/>
                            </div>
                            <h4 style={style.memberPosition}>{member.position}</h4>
                            <h3>{member.name}</h3>
                            <Link className="read_more" to={`/member?id=${member.id}`}>View Profile</Link>
                        </div>
                    </div>)
                }

                )}
            </div>
            <div style={{marginTop: 100, marginRight: 'auto', marginLeft: "auto", marginBottom: 100}}>
                <h3>Management</h3>
                <div style={style.separator}/>
            </div>
            <div className="service-grid">
                {teamDetails.map(member =>
                {
                    if(member.type === "management") return(
                    <div className="card" id={member.id}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: 'center',
                            alignItems: 'center'
                        }}>
                            <div style={style.imageContainer}>
                                <img style={style.memberImage} src={member.image} alt={"profile"}/>
                            </div>
                            <h4 style={style.memberPosition}>{member.position}</h4>
                            <h3>{member.name}</h3>
                            <Link className="read_more"  to={`/member?id=${member.id}`}>View Profile</Link>
                        </div>
                    </div>)
                })}
            </div>
        </section>
    );
}
