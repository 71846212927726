import React from 'react';

const NotFound = () => {
    return (
        <div style={{height: 300, width: "100%",flexDirection:"column", justifyContent:"center",display:"flex",alignContent:"center"}}>
            <h2>404 - Page Not Found</h2>
            <p>The page you're looking for does not exist.</p>
        </div>
    );
};

export default NotFound;
