import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./carouselStyle.css"
import backgroundImage2 from '../../../src/assets/images/background/towfiqu-barbhuiya-joqWSI9u_XM-unsplash.jpg'
import backgroundImage from '../../../src/assets/images/background/Climate-Finance.jpeg'
import backgroundImage3 from '../../../src/assets/images/background/micheile-dot-com-ZVprbBmT8QA-unsplash.jpg'

export default () => {
    return (
        <header>
            <OwlCarousel
                className='owl-theme'
                loop={true}
                nav
                dots={false}
                mouseDrag={false}
                autoplay={true}
                autoplayTimeout={10000}
                responsive={
                    {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                }
            >

                <div className="item">
                    <img src={backgroundImage}
                         alt="images not found"/>
                    <div className="cover">
                        <div className="container">
                            <div className="header-content">
                                <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                <h1>Vision</h1>
                                <h4>To be the most trusted and leading provider of
                                    investment solutions and platforms for value creation.</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="item">
                    <img src={backgroundImage2}
                         alt="images not found"/>
                    <div className="cover">
                        <div className="container">
                            <div className="header-content">
                                <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                <h1 > Values</h1>
                                <h4 style={{textAlign:"left"}}>Our core values highlight our ideals underpinning who we are:<br/>
                                    Integrity & Ethical Conduct Radical<br/>
                                    Transparency Customer First<br/>
                                    Teamwork<br/>
                                    Excellence</h4>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="item">
                    <img src={backgroundImage3}
                         alt="images not found"/>
                    <div className="cover">
                        <div className="container">
                            <div className="header-content">
                                <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                <h1 > What We Do</h1>
                                <h4>
                                    As an innovative investments and financial services firm, we continue to invest significantly in IT and Engineering systems that support automation and efficient service delivery
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </header>
    )
}

