import React from 'react'
import {Link, useLocation} from "react-router-dom";
import teamDetails from '../memberDetails'


const style = {
    main: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: 1,

    },
    image: {
        objectFit: "cover",

    },
    imageArea: {
        paddingTop: 50,
        display: "flex",
        width: "100%",
        height: "100%",
        flexGrow: 1,
        backgroundColor: "rgb(254, 45, 45)",
        // justifyContent: "center",
        // alignContent: "center",
        paddingBottom: 50,
        borderBottomRightRadius: 100,

    },
    textArea: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
    },
    textContainer: {
        width: "100",
        paddingLeft: "auto",
        paddingRight: "auto",
        margin: 50,
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
    }
}


export default () => {

    const location = useLocation()
    const id = new URLSearchParams(location.search).get('id')
    const member = teamDetails.find(member => member.id == id)


    return (
        <section style={style.main}>
            <div style={{...style.imageArea}}>

                <Link role="menuitem" to="/team" style={{color: "white"}}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            // backgroundColor: "rgb(222, 5, 12)",
                            backgroundColor: "#00aaea",
                            borderRadius: "20px",
                            border: "white solid 2px",
                            position: "relative",
                            float: "center",
                            marginRight: "-30rem",
                            marginTop: "9rem",
                            marginLeft: "0.5rem",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            height: 40,
                            width: 130
                        }}
                    >

                        <svg viewBox="-100.9 99.1 61.9 105.9"
                             style={{
                                 width: '8px', color: "white",
                                 marginRight: 10,
                                 marginLeft: 10,
                                 marginTop: "auto",
                                 marginBottom: "auto",
                                 fill: "white",
                             }}>
                            <path

                                d="M-98.2 158.8l43.5 43.5c1.7 1.7 4 2.7 6.5 2.7s4.8-1 6.5-2.7c1.7-1.7 2.7-4 2.7-6.5s-1-4.8-2.7-6.5l-37.2-37.2 37.2-37.2c1.7-1.7 2.7-4 2.7-6.5s-1-4.8-2.6-6.5c-1.8-1.9-4.2-2.8-6.6-2.8-2.3 0-4.6.9-6.5 2.6-11.5 11.4-41.2 41-43.3 43l-.2.2c-3.6 3.6-3.6 10.3 0 13.9z"/>
                        </svg>

                        <p
                            style={{
                                color: "white",
                                fontSize: 20,
                                marginTop: "4%",
                                marginRight: 20,
                            }}
                        >Back</p>

                    </div>
                </Link>


                <div style={{
                    width: 400,
                    height: 400,
                    borderRadius: 400 / 2,
                    overflow: "hidden",
                    marginRight: "auto",
                    marginLeft: "auto",
                }}
                >
                    <img
                        style={style.image}
                        src={member.image}
                        alt={"memberImage"}
                    />
                </div>


            </div>

            <div style={style.textArea}>
                <div style={style.textContainer}>
                    <p style={{fontSize: 25}}>{member.position}</p>
                    <h2>{member.name}</h2>
                    <p style={{textAlign: "left"}}>
                        {
                            member.Details.split(/\n/gi).map(paragraph => <p>{paragraph}</p>)
                        }
                    </p>
                </div>
            </div>
        </section>
    )
}
