import operator from "../../assets/icons/operator.svg";
import brokerage from "../../assets/icons/brokerage.svg";
import risk from "../../assets/icons/risk.svg";
import pension from "../../assets/icons/pension.svg";
import investors from "../../assets/icons/investors.svg";

export default [
    {
        id: 1,
        title: "Securities Trading & Brokerage services",
        description: "Longhorn is licensed by the Lusaka Securities Exchange to provide securities trading and brokerage services. The institution provides professional advice and facilitates the buying and selling of securities such as stocks and bonds.",
        icon: brokerage,
        unitTrust: null,
        points:null
    },
    {
        id: 2,
        title: "Pension Fund Management",
        description: "As a Fund Manager, Longhorn Associates manages pension funds and invests in a diverse portfolio of instruments and other investment options under its cover with the key objectives of capital preservation and growth as well as delivery of decent returns.\n" +
            "In addition to managing segregated pension funds, Longhorn Associates Limited also manages an umbrella employer fund called Longhorn Pension Trust Fund which is a collection of multiple employer pension schemes.",
        icon: pension,
        unitTrust: null,
        points:null
    },
    {
        id: 3,
        title: "Longhorn Unit Trust",
        icon: investors,
        description: "This is a Collective Investment Scheme under which various funds are managed. Specifically, we have the following under this trust:",
        unitTrust: [
            {
                title: "Longhorn Listed Equities Fund",
                description: "This fund principally invests in equities listed on the Lusaka Securities Exchange(LuSe).",
            },
            {
                title: "Longhorn Fixed Income Fund",
                description: "This fund invests in securities that pay across institutions of high repute and government bonds.",
            },
            {
                title: "Longhorn Listed Property (In-country & Global)",
                description: "Property Fund is designed to meet the existing and increasing demand from various clients wishing to have exposure to the property sector. The Fund shall invest in both retail and commercial property sector. The fund shall invest in both retail and commercial property. The investors must be aware that in order to exit, a buyer of their units should be found first.",
            },
            {
                title: "A Longhorn Multi Asset Fund ",
                description: " Longhorn Multi Asset Fund is a  mutual fund that invests in different securities including equities, bonds and money market funds, aimed at achieving a higher return while leveraging portfolio risk.",
            },
            {
                title: "Longhorn Education Fund",
                description: "This fund aims to assist investors plan for the education needs of their children, dependants and other parties.",
            },
            {
                title: "Longhorn Gratuity Fund",
                description: "This fund aims to provide reasonable returns and liquidity by investing in Government bonds, corporate bonds and money market instruments of different maturity and issues.",
            },

        ],
        points:null
    },
    {
        id: 4,
        title: "Advisory & Support Services",
        description: "Longhorn Associates has forged partnerships with international institutions for the provision of a selected number of products and service types including but not limited to the following:",
        icon: operator,
        unitTrust: null,
        points:[
            "Letters of Credit",
            "Letters of Guarantee",
            "Stock Brokerage services",
        ]
    },
    {
        id: 5,
        title: "Risk Management",
        description: "Through its focused research into the global economy and markets, Longhorn Associates has structured financial risk management products to counter the effects of the volatility of currencies and commodity prices.\n" +
            "\n" +
            "Put quite simply, the institution leverages on its extensive knowledge, experience, and continued research into the working of the economic machine to come up with structures to support mitigation of fluctuations in interest rates, Forex Trading and commodity prices.",
        icon: risk,
        unitTrust: null,
        points:null
    },
]


