import React, {useContext, useEffect, useState} from "react"
import "./style.css"
import {useForm} from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import ReactLoading from "react-loading";
import {UserContext} from "../../contexts/user.context";
import {useLocation, useNavigate} from "react-router";
import {TextField} from "@material-ui/core";


const style = {
    main: {
        padding: 0,
        margin: 0
    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 600,
    },
    textSectionHeading: {
        margin: "0px",
        fontWeight: 600,
        fontSize: "40px",
        textAlign: "center",
        lineHeight: 1,
        marginBottom: "2rem",
        color: "rgb(222, 5, 12)"
    },
}

export default () => {
    const {register, handleSubmit, watch, formState: {errors}, reset} = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)


    const navigate = useNavigate();
    const location = useLocation();

    // We are consuming our user-management context to
    // get & set the user details here
    const {user, fetchUser, emailPasswordLogin} = useContext(UserContext);

    // We are using React's "useState" hook to keep track
    //  of the form values.
    const [form, setForm] = useState({
        email: "",
        password: ""
    });

    // This function will be called whenever the user edits the form.
    const onFormInputChange = (event) => {
        const {name, value} = event.target;
        setForm({...form, [name]: value});
    };

    // This function will redirect the user to the
    // appropriate page once the authentication is done.
    const redirectNow = () => {
        const redirectTo = location.search.replace("?redirectTo=", "");
        navigate(redirectTo ? redirectTo : "/");
    }

    // Once a user logs in to our app, we don’t want to ask them for their
    // credentials again every time the user refreshes or revisits our app,
    // so we are checking if the user is already logged in and
    // if so we are redirecting the user to the home page.
    // Otherwise we will do nothing and let the user to login.
    const loadUser = async () => {
        if (!user) {
            const fetchedUser = await fetchUser();
            if (fetchedUser) {
                // Redirecting them once fetched.
                redirectNow();
            }
        }
    }

    // This useEffect will run only once when the component is mounted.
    // Hence this is helping us in verifying whether the user is already logged in
    // or not.
    useEffect(() => {
         loadUser(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This function gets fired when the user clicks on the "Login" button.
    const onSubmit = async (event) => {
        try {
            // Here we are passing user details to our emailPasswordLogin
            // function that we imported from our realm/authentication.js
            // to validate the user credentials and log in the user into our App.
            const user = await emailPasswordLogin(form.email, form.password);
            console.log(user)
            if (user) {
                redirectNow();
            }
        } catch (error) {
            if (error.statusCode === 401) {
                await MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Invalid username/password. Try again!",
                });
            } else {
                await MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message,
                });
            }
        }
    };

    return (
        <main className="row section--navy section--dark" id="main" role="main"
              style={{
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center"
              }}>

            <div id="contact" className="contact" style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "left",
                width: "100%"
            }}>
                <div className="container" style={{marginTop: "-200px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 style={style.textSectionHeading}>Login</h4>
                            <form onSubmit={handleSubmit(onSubmit)} className="main_form"
                                  style={{
                                      backgroundColor: "#fff",
                                      width: "65%",
                                      marginLeft: "auto",
                                      marginRight: "auto"
                                  }}>

                                <div className="row">

                                    <div className="col-md-12">
                                        <TextField
                                            variant={"outlined"}
                                            type="email"
                                            name="email"
                                            value={form.email}
                                            onChange={(event) => {
                                                setForm({
                                                    ...form,
                                                    email: event.target.value
                                                });
                                            }}
                                            placeholder={`${errors.email ? "This username is required" : "username"}`}
                                            className={`${errors.email ? "invalid_input" : "form_contril"}`}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <TextField
                                            variant={"outlined"}
                                            type="password"
                                            name="password"
                                            value={form.password}
                                            onChange={(event) => {
                                                setForm({
                                                    ...form,
                                                    password: event.target.value
                                                });
                                            }}
                                            placeholder={`${errors.password ? "This password is required" : "password"}`}
                                            className={`${errors.password ? "invalid_input" : "form_contril"}`}
                                        />
                                    </div>

                                    <div className="col-sm-12">
                                        {loading ? (
                                            <button disabled={true} className="send_btn">Logging in</button>
                                        ) : (
                                            <button className="send_btn" type="submit">Login</button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
