import React from "react"
import "./style.css"
import  retailOnBoardingForm from "../../assets/forms/Client-onboarding-registration-form-Phase-2-min-1.pdf"
import  instituteOnBoardingForm from "../../assets/forms/Institutional-Onboarding-Form-Longhorn-Associates-1.pdf"
import  brochuresForm from "../../assets/forms/Longhorn-associates-brochure-2020-min.pdf"
import  redemptionForm from "../../assets/forms/Redemption-Form-1.pdf"
import  companyProfileForm from "../../assets/forms/Longhorn Associates Company Profile.pdf"
import  stockBrokingForm from "../../assets/forms/LONGHORN STOCK BROKING FORM.pdf"
import  stockBrokingBrochure from "../../assets/forms/STOCK BROKING BROCHURE.pdf"


const styles = {
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
    }
}

export default () => {
    return (
        <main className="row section--navy section--dark" id="row-1" id="main" role="main">
            <div style={{height: 300, width:"100%"}} className="row section--navy section--dark" id="row-1">
                <section id="c-258500" role="banner" className="hero  hero--dark hero--with-image">
                    <div className="grid hero__grid">
                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                             style={{
                                 width: "100%",
                                 display: "flex",
                                 flexDirection: "column",
                                 alignItems: "center",
                                 alignContent: "center",
                             }}
                        >
                            <h1 className="h0 hero__heading">
                                <span className="u-nowrap-sm">Download Forms</span>
                            </h1>
                            <p style={{...styles.subText, color: "white", paddingLeft: 30, paddingRight: 30}}>
                                <h5>Below are downloadable forms for our customers:</h5>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
            <section className="lesun-service-area section_50 section_b_70" style={{width:"100%"}}>
                <div className="container" style={{textAlign:"left",width:"100%"}}>
                    <div className="row">

                        <p>&nbsp;</p>
                        <div className="col-md-8">

                        </div>
                        <div className="col-md-8">
                            <div className="single-blog-page-item">
                                <div className="blog-title">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '100%'}}>
                                                <h4>
                                                    <i className="fa fa-check" style={{visibility:"invisible"}}/>
                                                    <a href={instituteOnBoardingForm}>
                                                        Institutional Onboarding Form</a>
                                                </h4>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>f
                        </div>
                        <div className="col-md-8" >
                            <div className="single-blog-page-item">
                                <div className="blog-title">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '100%'}}>
                                                <h4>
                                                    <i className="fa fa-check"/>
                                                    <a href={retailOnBoardingForm}>
                                                        Retail Onboarding Form</a>
                                                </h4>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8" >
                            <div className="single-blog-page-item">
                                <div className="blog-title">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '100%'}}>
                                                <h4>
                                                    <i className="fa fa-check"/>
                                                    <a href={stockBrokingForm}>
                                                        Stock Broking Form</a>
                                                </h4>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="single-blog-page-item">
                                <div className="blog-title">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '100%'}}><h4><i className="fa fa-check"/> <a
                                                href={brochuresForm}>
                                                Brochures</a></h4></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="single-blog-page-item">
                                <div className="blog-title">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '100%'}}><h4><i className="fa fa-check"/> <a
                                                href={stockBrokingBrochure}>
                                                Stock Broking Brochure</a></h4></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="single-blog-page-item">
                                <div className="blog-title">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '100%'}}>
                                                <h4>
                                                    <i className="fa fa-check"/>
                                                    <a href={redemptionForm}>
                                                        Redemption Form
                                                    </a>
                                            </h4>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="single-blog-page-item">
                                <div className="blog-title">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '100%'}}><h4>
                                                <i className="fa fa-check"/>
                                                <a
                                                href={companyProfileForm}>
                                                    Company Profile
                                                </a>
                                            </h4>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
