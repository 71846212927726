import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Routes from '../src/routes';
import {UserContext, UserProvider} from "./contexts/user.context";


const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        flexGrow: 1
    },
    header: {
        zIndex: 2000800000
    },
    main: {
        zIndex: 1
    },
    drawer: {
        flexGrow: 1,
        marginRight: "10rem",
    }
}));

function App() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <UserProvider>
                <Header/>
                <Routes style={classes.main}/>
                <Footer/>
            </UserProvider>
        </div>
    )
}

export default App;
