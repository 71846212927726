import React from 'react'
import './style.css'
import "./cardCarouselStyle.css"
import "./sectionStyle.css"
import backgroundImage2 from '../../../src/assets/images/background/towfiqu-barbhuiya-jpqyfK7GB4w-unsplash.jpg'
import accountImage3 from '../../../src/assets/images/background/woman-accountant.jpeg'

import investors from '../../../src/assets/icons/investors.svg'
import operator from '../../../src/assets/icons/operator.svg'
import risk from '../../../src/assets/icons/risk.svg'
import pension from '../../../src/assets/icons/pension.svg'

import {Link} from "react-router-dom";
import MyCarousel from "../../components/myCarousel";

const style = {
    root: {},
    textSectionHeading: {
        margin: "0px",
        fontWeight: 600,
        fontSize: "50px",
        textAlign: "center",
        lineHeight: 1,
        marginBottom: "2rem",
        color: "rgb(222, 5, 12)"
    },
    textSectionContainer: {
        display: 'flex',
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        maxWidth: '80%',
        paddingTop: "5rem",
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",

    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
    }
}


export default () => {


    return (
        <div style={style.root}>

            <MyCarousel style={{margin: 10}}/>

            <div className="media-block" id="c-258504">
                <div className="media-block__desc" style={style.textSectionContainer}>
                    <h3 style={style.textSectionHeading}>Mission statement</h3>
                    <p style={style.subText}>
                        We are committed to enriching society through the delivery of superior
                        investment products and services.We respect diversity and believe in transparency
                        and an open exchange of views in all our decision-making and business processes.
                        In our pursuit for growth and continued creation of value, we put our clients at the
                        center of our business, building sustainable and long-standing relationships anchored on trust
                    </p>
                </div>
            </div>

            <div
                className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                style={{
                    backgroundColor: 'rgba(255,255,255,0)',
                    backgroundPosition: 'left top',
                    backgroundRepeat: 'no-repeat',
                    paddingTop: '60px',
                    paddingBottom: '70px',
                    marginBottom: '0px',
                    marginTop: '0px',
                    borderWidth: '0px 0px 0px 0px',
                    borderColor: '#eae9e9',
                    borderStyle: 'solid'
                }}>

                <div style={{
                    backgroundColor: "#f9f9f9",
                    width: "100%"
                }}>
                    <div className="media-block" id="c-258504">
                        <div className="media-block__desc" style={style.textSectionContainer}>
                            <h3 style={style.textSectionHeading}> Products and Services </h3>
                            <div style={{backgroundColor: "red", height: "0.1rem", width: "60%"}}/>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="card">
                            <div className="img-container">
                                <img src={operator}/>
                            </div>
                            <h3 className="card-title">Advisory &amp; Support Services</h3>
                            <p className="card-description">Longhorn Associates has forged partnerships with
                                international...</p>
                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    height: 100,
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/services"} className="read_more"
                                          style={{borderColor: "#fff", color: "#fff"}}>Read More </Link>
                                </div>
                            </Link>

                        </div>

                        <div className="card">
                            <div className="img-container">
                                <img src={risk}/>
                            </div>
                            <h3 className="card-title" style={{fontSize:20}}>Securities Trading &amp; Brokerage services</h3>
                            <p className="card-description">Longhorn is licensed by the Lusaka Securities Exchange to
                                provide ...
                            </p>
                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    height: 40,
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/services"} className="read_more"
                                          style={{borderColor: "#fff", color: "#fff"}}>Read More </Link>

                                </div>
                            </Link>
                        </div>

                        <div className="card">
                            <div className="img-container">
                                <img src={pension}/>
                            </div>
                            <h3 className="card-title">Pension Fund Management</h3>
                            <p className="card-description">As a Fund Manager, Longhorn Associates manages pension funds
                            </p>
                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    height: 40,
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/services"} className="read_more"
                                          style={{borderColor: "#fff", color: "#fff"}}>Read More </Link>

                                </div>
                            </Link>

                        </div>

                        <div className="card">
                            <div className="img-container">
                                <img src={investors}/>
                            </div>
                            <h3 className="card-title">Longhorn Unit Trust</h3>
                            <p className="card-description">This is a Collective Investment Scheme under which various ...
                            </p>

                            <Link
                                className="card-button"
                                style={{color: "white"}}
                                to="/services">
                                <div style={{
                                    width: "100%",
                                    height: 40,
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to={"/services"} className="read_more"
                                          style={{borderColor: "#fff", color: "#fff"}}>Read More </Link>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    backgroundColor: "red",
                    marginBottom:100
                }}
            >
                <div
                    className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                    style={{
                        backgroundColor: 'rgba(10,19,35,.15)',
                        backgroundImage: `url(${backgroundImage2})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundBlendMode: 'hue',
                        paddingTop: '72px',
                        paddingBottom: 0,
                        marginTop: 0,
                        borderWidth: '0 0 0 0',
                        borderColor: '#eae9e9',
                        borderStyle: 'solid',
                        WebkitBackgroundSize: 'cover',
                        MozBackgroundSize: 'cover',
                        OBackgroundSize: 'cover',
                        backgroundSize: 'cover'
                    }}>

                    <div className="media-block" id="c-258504">
                        <div className="media-block__desc" style={style.textSectionContainer}>
                            <h3 style={style.textSectionHeading}>Our Principles & Culture</h3>
                            <p style={{...style.subText, color: "white"}}>
                                We believe that our success is anchored on developing meaningful working
                                relationships internally and extending the quality of those relationships to our
                                clients and other external stakeholders. Our culture promotes openness, transparency
                                and honest exchange of views for the purpose of attaining excellence in service
                                delivery and value creation. Paying attention to detail remains at the centre of our
                                principles through which we make sound decisions capable of delivering the required
                                value.
                            </p>
                        </div>
                    </div>
                </div>
            </div>




            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    backgroundColor: "red",
                }}
            >
                <div
                    className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                    style={{
                        backgroundColor: 'rgba(10,19,35,.15)',
                        backgroundImage: `url(${accountImage3})`,
                        // backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${accountImage3})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundBlendMode: 'hue',
                        paddingTop: '72px',
                        paddingBottom: 0,
                        marginTop: 0,
                        borderWidth: '0 0 0 0',
                        borderColor: '#eae9e9',
                        borderStyle: 'solid',
                        WebkitBackgroundSize: 'cover',
                        MozBackgroundSize: 'cover',
                        OBackgroundSize: 'cover',
                        backgroundSize: 'cover'
                    }}>

                    <div className="media-block" id="c-258504">
                        <div className="media-block__desc" style={style.textSectionContainer}>
                            <h3 style={style.textSectionHeading}>Our Investment Philosophy</h3>
                            <p style={{...style.subText, color: "white"}}>
                                We seek to invest in businesses with growth potential. At the core of our investment
                                decisions
                                is the desire to be actively involved in the growth journey of the businesses we invest
                                in.
                                We particularly focus on identification of talent, capacity building and provision of
                                capital
                                and other resources to ensure the sustainable success of our businesses.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/*image section  */}
            <div
                className="fusion-layout-column fusion_builder_column fusion-builder-column-9 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                style={{marginTop: '0px', marginBottom: '20px'}}>
                <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{
                    backgroundPosition: 'left top',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    padding: '0px',
                    minHeight: '0px'
                }}>
                    <div className="imageframe-align-center">
                            <span
                                className="fusion-imageframe imageframe-none imageframe-1 hover-type-none fusion-animated"
                                data-animationtype="fadeInDown"
                                data-animationduration="0.8"
                                data-animationoffset="100%"
                                style={{visibility: 'visible', animationDuration: '0.8s'}}>
                                <a
                                    className="fusion-no-lightbox" href="https://bit.ly/2wVX4yE" target="_blank"
                                    aria-label="money-moves" rel="noopener noreferrer">
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            {/*image section end */}
        </div>
    );
}

