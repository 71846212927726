import React, {useContext, useState} from "react"
import "./style.css"
import {useForm} from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import {UserContext} from "../../contexts/user.context";


const style = {
    root: {},
    textSectionHeading: {
        margin: "0px",
        fontWeight: 600,
        fontSize: "40px",
        textAlign: "center",
        lineHeight: 1,
        marginBottom: "2rem",
        color: "rgb(222, 5, 12)"
    },
    textSectionContainer: {
        display: 'flex',
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        maxWidth: '80%',
        paddingTop: "5rem",
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",

    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
    }
}

export default () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)
    const {user, logOutUser} = useContext(UserContext);

    const logout = () => {
        logOutUser()
        window.location.reload()
    }

    const onSubmit = async data => {
        setLoading(true);
        MySwal.fire({
            title: 'Updating Yields',
            color: 'rgb(254, 45, 45)',
            allowOutsideClick: false,
            html: (
                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: "column"
                }}>
                    <h4>Please wait..</h4>
                    <ReactLoading type={'bubbles'} color='rgb(254, 45, 45)' height={'20%'} width={'20%'}/>
                </div>
            ),
            showConfirmButton: false,
        });
        try {
            const result = await user.functions.updateRateAndYields({body: data});
            console.log("result", result)
            let onClickResult = await MySwal.fire({
                icon: 'success',
                title: 'Yields updated',
                text: 'Yields updated successfully!',
            });
            if (onClickResult.isConfirmed) {
                reset()
                window.location.reload()
            }
            setLoading(false);
        } catch (e) {
            await MySwal.fire({
                icon: 'error',
                title: 'Error Updating Yields',
                text: 'Something went wrong. Please try again later.',
            });
            setLoading(false);
        }
        setFormSubmitted(true);
    };
    return (
        <main className="row section--navy section--dark" id="main" role="main"
              style={{
                  marginTop: -200,
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center"
              }}>

            <div id="contact" className="contact" style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "left",
                width: "100%"
            }}>

                <h4 style={style.textSectionHeading}>Rates and Yields</h4>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={handleSubmit(onSubmit)} className="main_form"
                                  style={{
                                      backgroundColor: "#fff",
                                      width: "65%",
                                      marginLeft: "auto",
                                      marginRight: "auto"
                                  }}>

                                <div className="row">
                                    <div className="col-md-12">
                                    <textarea
                                        style={{
                                            height: 200,
                                            '::placeholder': {
                                                color: "grey",
                                            },
                                        }}
                                        name="data"
                                        defaultValue={""}
                                        placeholder={`${errors.message ? "Rates and yields is required" : "Enter rates and yields"}`}
                                        className={`${errors.message ? "invalid_input" : "form_contril"}`}
                                        {...register("data", {required: true})}
                                    />
                                    </div>

                                    <div className="col-sm-12 row">
                                        {loading ? (
                                            <button disabled={true} className="send_btn">Saving</button>
                                        ) : (
                                            <button className="send_btn" type="submit">Save</button>

                                        )}
                                        <button className="send_btn" onClick={logout}>Logout</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
