import React from "react"
import "./style.css"
import ServicesSection from "./SevicesSection";
import ServiceCarousel from "../../components/ServiceCarousel";


// <div className="row section--navy section--dark" id="row-1">
//     <section
//         id="c-258500"
//         role="banner"
//         className="hero  hero--dark hero--with-image"
//     >
//         <div className="grid hero__grid">
//             <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
//                  style={{
//                      backgroundPosition: 'left top',
//                      backgroundBlendMode: 'overlay',
//                      backgroundRepeat: 'no-repeat',
//                      backgroundSize: 'cover',
//                      minHeight: '0px',
//                      display: "flex",
//                      border: 100,
//                      borderColor: "white",
//                      flexDirection: "column",
//                      alignItems: "center",
//                      textAlign: "left",
//                      padding: 30,
//                  }}
//             >
//                 <h1 className="h0 hero__heading"
//
//                 >
//                     <span className="u-nowrap-sm"> Products and Services </span>
//                 </h1>
//                 <h4 style={{
//                     ...styles.subText,
//                     fontSize: 20,
//                     color: "#fff",
//                     paddingLeft: 30,
//                     paddingRight: 30
//                 }}>
//                     As a Fund Manager, Longhorn Associates manages pension funds and
//                     invests in a diverse portfolio of instruments and other
//                     investment options under its cover, with the key
//                     objectives of capital preservation and growth
//                     as well as delivery of decent returns.

//                 </h4>
//             </div>
//         </div>
//     </section>
// </div>

const styles = {
    main: {
        padding: 0,
        margin: 0
    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
        textAlign:"justify"
    }
}


export default () => {
    return (
        <main style={styles.main}>
           <ServiceCarousel/>
            <section className="section section--white" id="section-2" style={{backgroundColor: "rgb(249, 249, 249)"}}>
                <div className="section__container container">
                    <ServicesSection/>
                </div>
            </section>
        </main>
    );
}
