import React, {useState} from "react"
import {useForm} from "react-hook-form";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Shimmer from "react-shimmer-effect";
import ShimmerButton from "react-shimmer-effect";
import axios from 'axios';
import ReactLoading from 'react-loading';
import "./style.css"

const styles = {
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
    }
}

export default () => {
    const {register, handleSubmit, watch, formState: {errors},reset} = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)


    const onSubmit = data => {
        setLoading(true);
        MySwal.fire({
            title: 'Sending email',
            color:'rgb(254, 45, 45)',
            allowOutsideClick: false,
            html: (
                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection:"column"
                }}>
                    <h4>Please wait..</h4>
                    <ReactLoading type={'bubbles'} color='rgb(254, 45, 45)' height={'20%'} width={'20%'} />
                </div>
            ),
            showConfirmButton: false,
        });
        axios.post('https://data.mongodb-api.com/app/application-0-mbwce/endpoint/email/info', data)
            .then(response => {
                MySwal.fire({
                    icon: 'success',
                    title: 'Email sent successfully!',
                    text: 'Thank you for your message.',
                });
                reset()
                setLoading(false);
            })
            .catch(error => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong. Please try again later.',
                });
                setLoading(false);
            });
        setFormSubmitted(true);
    };


    return (
        <main className="row section--navy section--dark" id="row-1" id="main" role="main"
              style={{
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center"
              }}>
            <div style={{height: 300}} className="row section--navy section--dark" id="row-1">
                <section id="c-258500" role="banner" className="hero  hero--dark hero--with-image">
                    <div className="grid hero__grid">
                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                             style={{
                                 backgroundPosition: 'left top',
                                 backgroundBlendMode: 'overlay',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundSize: 'cover',
                                 minHeight: '0px',
                                 display: "flex",
                                 flexDirection: "column",
                                 alignItems: "center",
                                 textAlign: "center",
                                 padding: 30,
                                 alignContent: "center",
                                 marginLeft: "auto",
                                 marginRight: "auto",
                                 maxWidth: "40rem"

                             }}
                        >
                            <h1 className="h0 hero__heading">
                                <span className="u-nowrap-sm">Contact Us</span>
                            </h1>
                            <p style={{...styles.subText, color: "white", width: "900px", textAlign: "justify"}}>
                                Longhorn Associates has a team of qualified and experienced professionals from diverse
                                fields serving on its Board and providing governance oversight of the institution.
                            </p>
                        </div>
                    </div>
                </section>
            </div>

            <div id="contact" className="contact" style={{marginTop: -10}}>
                <form onSubmit={handleSubmit(onSubmit)} className="main_form"
                      style={{backgroundColor: "#fff", width: "65%", marginLeft: "auto", marginRight: "auto"}}>

                    <div className="row">

                        <div className="col-md-12">
                            <input
                                type="text"
                                name="Name"
                                placeholder={`${errors.Name ? "This name is required" : "name"}`}
                                className={`${errors.Name ? "invalid_input" : "form_contril"}`}
                                {...register("Name", {required: true})}
                            />
                        </div>

                        <div className="col-md-12">
                            <input
                                type="number"
                                name="phoneNumber"
                                placeholder={`${errors.phoneNumber ? "*his phone number is required" : "phoneNumber"}`}
                                className={`${errors.phoneNumber ? "invalid_input" : "form_contril"}`}
                                {...register("phoneNumber", {required: true})}
                            />
                        </div>

                        <div className="col-md-12">
                            <input
                                type="email"
                                name="email"
                                placeholder={`${errors.email ? "This email is required" : "email"}`}
                                className={`${errors.email ? "invalid_input" : "form_contril"}`}
                                {...register("email", {required: true,pattern: /^\S+@\S+$/i})}
                            />
                        </div>

                        <div className="col-md-12">
                            <input
                                type="text"
                                name="subject"
                                placeholder={`${errors.subject ? "This subject is required" : "subject"}`}
                                className={`${errors.subject ? "invalid_input" : "form_contril"}`}
                                {...register("subject", {required: true})}
                            />

                        </div>

                        <div className="col-md-12">
                                    <textarea
                                        name="message"
                                        defaultValue={""}
                                        placeholder={`${errors.message ? "This message is required" : "message"}`}
                                        className={`${errors.message ? "invalid_input" : "form_contril"}`}
                                        {...register("message", {required: true})}
                                    />
                        </div>

                        <div className="col-sm-12">
                            {loading ? (
                                <button disabled={true} className="send_btn" >Sending</button>
                            ) : (
                                <button className="send_btn" type="submit">Send</button>
                            )}
                        </div>
                    </div>
                </form>
            </div>

            <h1 style={{marginTop: 50, color: "black", marginLeft: 'auto', marginRight: "auto"}}>Pay us a visit</h1>
            <div style={{
                height: 3,
                width: "50%",
                backgroundColor: 'red',
                marginRight: "auto",
                marginLeft: "auto"
            }}/>
            <div style={{width: "100%", height: 600, margin: 30, marginTop: 50}}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.375874841212!2d28.301944014850065!3d-15.410251389290977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408b71d3dbb959%3A0xd648e3f01a839f7d!2sLonghorn+Associates+Limited!5e0!3m2!1sen!2ske!4v1536235728739"
                    width={1100} height={500} frameBorder={0} style={{border: 0}} allowFullScreen/>
            </div>
        </main>
    );
}
