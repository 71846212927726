import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./carouselStyle.css"
import backgroundImage2 from '../../../src/assets/images/background/services.jpg'
import backgroundImage from '../../../src/assets/images/background/service1.jpg'

export default () => {
    return (
        <header>
            <OwlCarousel
                className='owl-theme'
                loop={true}
                nav
                dots={false}
                mouseDrag={false}
                autoplayTimeout={15000}
                autoplay={true}
                responsive={
                    {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                }
            >

                <div className="item">
                    <img src={backgroundImage}
                         alt="images not found"/>
                    <div className="cover">
                        <div className="container">
                            <div className="header-content">
                                <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                <h1>Products and services</h1>
                                <h4>          As a Fund Manager, Longhorn Associates manages pension funds and
                                                         invests in a diverse portfolio of instruments and other
                                                         investment options under its cover, with the key
                                                    objectives of capital preservation and growth
                                                         as well as delivery of decent returns.</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="item">
                    <img src={backgroundImage2}
                         alt="images not found"/>
                    <div className="cover">
                        <div className="container">
                            <div className="header-content">
                                <div className="line" style={{borderColor: "rgb(222, 5, 12)"}}/>
                                <h1>Products and services</h1>
                                <h4 style={{textAlign:"justify",fontStyle:"bold"}}>

                                    In addition to managing segregated pension funds, Longhorn
                                    Associates Limited also manages an umbrella employer fund called
                                    Longhorn Pension Trust Fund which is a collection of multiple employer
                                    pension schemes.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </header>
    )
}

