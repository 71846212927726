import React from 'react'
import TeamUnit from './teamUnit'

const styles = {
    main:{
        padding:0,
        margin:0
    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 500,
    }
}

export default () => {

    return (
        <main style={styles.main}>
            <div className="row section--navy section--dark" id="row-1">
                <section id="c-258500" role="banner" className="hero  hero--dark hero--with-image">


                    <div className="grid hero__grid">

                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                             style={{
                                 backgroundPosition: 'left top',
                                 backgroundBlendMode: 'overlay',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundSize: 'cover',
                                 minHeight: '0px',
                                 display: "flex",
                                 flexDirection: "column",
                                 alignItems: "center",
                                 textAlign: "center",
                                 padding: 30,
                                 alignContent:"center",
                                 marginLeft:"auto",
                                 marginRight:"auto",
                                 maxWidth:"40rem"

                             }}
                        >
                            <h1 className="h0 hero__heading">
                                <span className="u-nowrap-sm">Meet Our People </span>
                            </h1>
                            <p style={{fontSize: 20, paddingLeft: 30, paddingRight: 30,textAlign:"justify"}}>
                                Longhorn Associates has a team of qualified and experienced professionals from diverse
                                fields serving on its Board and providing governance oversight of the institution.
                            </p>
                        </div>
                    </div>
                </section>
            </div>

            <section className="section section--white" id="section-2">
                <div className="section__container container">
                    <TeamUnit/>
                </div>
                <section style={{backgroundColor:"rgb(254, 45, 45)",marginBottom:0}} id="c-258500" role="banner" className="hero  hero--dark hero--with-image">
                    <div className="section__container container">
                        <div >
                            <div
                                className="hero__content hero__content--right grid__col grid__col--xs-12 grid__col--sm-12 grid__col--md-12 grid__col--lg-6">
                                <h1 className="h0 hero__heading">
                                    <span className="u-nowrap-sm">Board Sub - Committees</span>
                                </h1>
                                <p style={{fontSize: 16}}>
                                    The Board is run through sub-committees each of which is responsible
                                    for specific governance aspects of the institution. All the sub-committees
                                    are chaired by Independent Non-executive Directors.
                                </p>
                                <ul style={{textAlign:"left"}}>
                                    <li>
                                        •	 Appointments & Remuneration Committee
                                    </li>
                                    <li>
                                        •	 Investments Committee
                                    </li>
                                    <li>
                                        •	 Risk & Compliance Committee
                                    </li>
                                    <li>
                                        •	Finance & Audit Committee
                                    </li>
                                    <li>
                                        •	Appointments & Remuneration Committee
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    )
}
