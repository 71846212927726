import React, {useState} from 'react'
import {Link} from "react-router-dom";


const data = {
    investmentPhilosophy: {
        details: "Our investment philosophy is anchored on our belief that sound investment decisions are a result of well researched and analysed work supported with a sense of radical transparency in the selection of investment alternatives." + "We seek to invest in businesses with exceptional earnings growth, driven by a sustainable competitive advantage, superior financial strength, proven management and attractive products. We believe that such exceptional companies not only have the potential to contribute competitive returns but are also inherently less risky.",
        name: "investment Philosophy"
    },
    OurPrinciplesAndCulture: {
        details: "We believe that our success is anchored on developing meaningful working relationships internally and extending the quality of those relationships to our clients and other external stakeholders. Our culture promotes openness, transparency and honest exchange of views for the purpose of attaining excellence in service delivery and value creation. Paying attention to detail remains at the centre of our principles through which we make sound decisions capable of delivering the required value.",
        name: "Our Principles & Culture"
    },
    whatWeDo: {
        details: "As an investments firm, Longhorn Associates focuses on understanding how the global economy and markets work. The institution then transforms that understanding into meaningful investment decisions formed on the basis of established sound portfolios and strategic partnerships with individual and institutional clients. The company has therefore invested and continues to invest significantly in processes that support continuous research and gathering of useful, up-to-date and meaningful information to support our decision making processes.",
        name: "What We Do"
    },
}

const style = {
    card: {
        color: "black"
    },
    cardHeader: {},
    headSection: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center"
    },

    detailShow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // alignContent: "center",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        justifyItems: "center",
        width: "75%",
        marginTop:100
    },
    detailHidden: {
        display: "none",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center"
    },




    cardDetail: {},
}

export default () => {

    const [isActive, setIsActive] = useState(false);
    const [clickedItemName, setClickedItemName] = useState("");
    let cardDetailsRef = React.createRef();


    const handleClick = (event, name) => {
        document.querySelector('[id="space"]').scrollIntoView();
        console.log(name)
        setClickedItemName(name)
        setIsActive(true);
    };

    const getDetails = () => {
        if (clickedItemName === "Investment Philosophy") {
            return data.investmentPhilosophy.details
        } else if (clickedItemName === "Our Principles") {
            return data.OurPrinciplesAndCulture.details
        } else {
            return data.whatWeDo.details
        }
    }
    const getTitle = () => {
        if (clickedItemName === "Investment Philosophy") {
            return data.investmentPhilosophy.name
        } else if (clickedItemName === "Our Principles") {
            return data.OurPrinciplesAndCulture.name
        } else {
            return data.whatWeDo.name
        }
    }

    return (

        <div className="row section--navy section--dark" id="row-1">

            <section
                id="c-258500"
                role="banner"
                className="hero  hero--dark hero--with-image"
                style={isActive ? {display:"none"} : {display:"flex"}}
            >
                <div className="grid hero__grid">
                    <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                         style={{
                             backgroundPosition: 'left top',
                             backgroundBlendMode: 'overlay',
                             backgroundRepeat: 'no-repeat',
                             backgroundSize: 'cover',
                             minHeight: '0px',
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                             textAlign: "center",
                             padding: 30,
                         }}
                    >
                        <h1 className="h0 hero__heading">
                            <span className="u-nowrap-sm"> About Us </span>
                        </h1>
                        <p style={{fontSize: 20, color: "#fff",paddingLeft: 30, paddingRight: 30,textAlign:"justify"}}>
                            Longhorn Associates Limited is an Investment Management Company regulated by the Securities
                            & Exchange Commission (SEC), the Pensions & Insurance Authority (PIA) and the Lusaka
                            Securities Exchange (LuSE). It is also a member of the Capital Markets Association of Zambia
                            (CMAZ) and the Zambia Association of Pension Funds (ZAPF).
                            The financial Institution provides investment products and services to both retail and
                            institutional clients. Some of the firm’s key products include pension fund management;
                            collective investment schemes (CIS) comprising various funds that include gratuity funds,
                            education, fixed income, education, property and equity funds, among others. Longhorn also
                            provides stock / securities trading and advisory services.
                            Over the years, Longhorn has become a platform that drives transformation whilst maintaining
                            a clear focus on delivering the much-needed returns on clients’ savings and investments.
                        </p>
                    </div>
                </div>
            </section>
            <div id="space" style={{height:50}}/>
            <div
                ref={cardDetailsRef}
                id={'cardDetails'}
                style={isActive ? style.detailShow : style.detailHidden}>
                <div className="card">
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: 'center',
                        alignItems: 'center'
                    }}>


                        <div style={{
                            width:"100%",
                            display:"flex",
                            alignItems:"end",
                            justifyContent:"end",
                            marginBottom:50,
                            cursor:"Pointer"
                        }}

                           onClick={e=> {
                               setIsActive(false)
                               console.log("close clicked",isActive)
                           }}
                        >
                            <svg
                                style={{   height:30,}}
                                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50" xmlSpace="preserve">
                                <g id="Layer_3">
                                    <line id="path2" fill="none" stroke="#000000" strokeWidth={3} strokeMiterlimit={10} x1="8.5" y1="41.5" x2="41.5" y2="8.5" />
                                    <line id="path3" fill="none" stroke="#000000" strokeWidth={3} strokeMiterlimit={10} x1="41.5" y1="41.5" x2="8.5" y2="8.5" />
                                </g>
                            </svg>
                        </div>



                        <h3 style={{color: "red"}}>{getTitle()}</h3>
                        <p style={{fontSize: 18, color: "black"}}>
                            {
                                getDetails()
                            }
                        </p>
                    </div>
                </div>
            </div>

            <section className="section section--white" id="section-2" style={{backgroundColor: "rgb(254, 45, 45)"}}>
                <div className="section__container container">
                    <div className="service-grid" style={{color: "black"}}>

                        <Link to={"#"}>
                            <div className="card" name={"Investment Philosophy"}
                                 onClick={e => handleClick(e, "Investment Philosophy")}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyItems: 'center',
                                    alignItems: 'center'
                                }}>
                                    <span className="bg-color-3" style={{margin: 20}}/>
                                    <h3 style={{color: "red"}}>Investment Philosophy</h3>
                                    <p style={{fontSize: 18}}>
                                        Our investment philosophy is anchored on our belief that sound ...</p>
                                </div>
                            </div>
                        </Link>

                        <Link to={"#"}>
                            <div className="card" name={" Our Principles"}
                                 onClick={e => handleClick(e, "Our Principles")}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyItems: 'center',
                                    alignItems: 'center'
                                }}>
                                    <span className="bg-color-4" style={{margin: 20}}/>
                                    <h3 style={{color: "red"}}> Our Principles<br/> & Culture</h3>
                                    <p style={{fontSize: 18}}>
                                        We believe that our success is anchored on developing ...</p>
                                </div>
                            </div>
                        </Link>

                        <Link to={"#"}>
                            <div className="card" name={"What we do"} onClick={e => handleClick(e, "What we do")}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyItems: 'center',
                                    alignItems: 'center'
                                }}>
                                    <span style={{margin: 20, backgroundColor: "red"}}/>
                                    <h3 style={{color: "red"}}>What we do</h3>
                                    <p style={{fontSize: 18}}>
                                        As an investments firm, Longhorn Associates focuses on understanding ...</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>

            <section id="c-258500" role="banner" className="hero  hero--dark hero--with-image">
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }} className="grid hero__grid">
                    <div
                        id='wave'
                        className="fusion-section-separator section-separator waves-opacity fusion-section-separator-1"
                        style={{width: "100%"}}>
                        <svg
                            style={{width: "100%", marginBottom: -10}}
                            className="fusion-waves-opacity-candy" fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%"
                            viewBox="0 0 1024 216" preserveAspectRatio="none">
                            <path className="st0"
                                  d="M0 216.068h1024l.1-105.2c-14.6-3.2-30.2-5.8-47.1-7.6-178.6-19.6-279.5 56.8-464.3 41.3-206.5-17.2-248.4-128.8-455-143.8-19-1.3-38.3-.2-57.7.3v215z"/>
                            <path className="st1"
                                  d="M0 20.068v196.144h1024v-79.744c-22.7-6.4-47.9-11.4-76.2-14.6-178.6-19.8-272.2 53.9-457.1 38.4-206.6-17.2-197.3-124.7-403.9-139.8-27.2-2-56.6-2-86.8-.4z"/>
                            <path className="st2"
                                  d="M0 216.212h1024v-35.744c-45.1-15.4-95.2-27.7-150-33.7-178.6-19.8-220.6 46.8-405.4 31.3-206.6-17.2-197.8-114.7-404.4-129.7-20.4-1.5-42-2-64.2-1.7v169.544z"/>
                            <path className="st3"
                                  d="M0 216.312h1024v-3.044c-50.8-17.1-108.7-30.7-172.7-37.9-178.6-19.8-220 36.8-404.9 21.3-206.6-17.2-228-126.5-434.5-141.6-3.9-.3-7.9-.5-11.9-.7v161.944z"/>
                        </svg>
                    </div>
                </div>
            </section>
        </div>
    )
}
